import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  permission: string;
  featureFlag?: string;
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sidebarRightOpen = false;
  public sidebarRightOpened = false;

  defaultMenu: IMenuItem[] = [
    {
      name: 'Performance Management',
      type: 'link',
      tooltip: 'Reporting',
      icon: 'bar_chart',
      state: 'reports',
      permission: 'menu.dashboard'
    },
    {
      name: 'Dialog designer',
      type: 'link',
      tooltip: 'Dialog designer',
      icon: 'format_shapes',
      state: 'dialogues',
      permission: 'menu.dialogs'
    },
    {
      name: 'Conversations',
      type: 'link',
      tooltip: 'Conversations',
      icon: 'chat',
      state: 'conversations',
      permission: 'menu.conversations'
    },
    {
      name: 'Test virtual agent',
      type: 'link',
      tooltip: 'Test virtual agent',
      icon: 'play_circle_filled',
      state: 'chat',
      permission: 'menu.virtual-agent'
    },
    {
      name: 'Reports',
      type: 'link',
      tooltip: 'Reporting',
      icon: 'reorder',
      state: 'client-reports',
      permission: 'menu.client-reports'
    },
    {
      name: 'Waiting list',
      type: 'link',
      tooltip: 'Waiting list',
      icon: 'pending_actions',
      state: 'waiting-list',
      permission: 'menu.waiting-list'
    },
    {
      name: 'Support',
      type: 'extLink',
      tooltip: 'Support',
      icon: 'live_help',
      state:
        'mailto:support@ebo.ai?subject=Issue%2FSupport%20Query&body=Update%20subject%20line%20if%20required%20and%20report%20issue%20%2F%20support%20question%20below.',
      permission: 'menu.support'
    },
    {
      name: 'Hangfire Jobs',
      type: 'extLink',
      tooltip: 'Hangfire jobs',
      icon: 'assignment',
      state: 'hangfire',
      permission: 'menu.hangfire-job'
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
}
